import React from 'react'
import styled from '@emotion/styled'
import {motion} from 'framer-motion'
// import street from '../../assets/street/street3.jpg'
import library from '../../assets/library/library.jpg'
import {MEDIA} from '../../utils/constants'

type Props = {
  title: string
  subtitle: string
  paragraph: string
}

const PageTitleSecondary = ({title, subtitle, paragraph}: Props) => {
  return (
    <BackgroundDiv>
      <TitleContainer>
        <Title
          initial={{opacity: 0, y: -60}}
          animate={{opacity: 1, y: 0}}
          transition={{duration: 1.3}}
        >
          {title}
        </Title>
        <Subtitle>{subtitle}</Subtitle>
        <HeroParagraph>{paragraph}</HeroParagraph>
      </TitleContainer>
    </BackgroundDiv>
  )
}

export default PageTitleSecondary

const BackgroundDiv = styled(motion.div)`
  background-image: url(${library});
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
`

const TitleContainer = styled(motion.div)`
  position: relative;
  width: 100%;
  min-height: calc(100vh - var(--navbar-height));
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  flex: 1;
  margin: 0;
  padding: 0 2rem 0.5rem 1rem;
  gap: 0.5rem;

  background: rgba(0, 0, 0, 0.4);
  /* background: linear-gradient(
    90deg,
    rgba(0, 0, 0, 0.8) 0%,
    rgba(195, 122, 162, 0.8) 100%
  ); */
  background: linear-gradient(
    90deg,
    rgba(0, 0, 0, 0.8) 0%,
    var(--imageColor) 100%
  );
  /* background: rgba(30, 61, 87, .8); */
  /* background: linear-gradient(90deg, 
    rgba(0,0,0,.8) 0%, 
    rgba(30, 61, 87, .8) 100%); */

  /* backdrop-filter: blur(4px); */

  @media (min-width: ${MEDIA.tablet}) {
    padding-left: 2.5rem;
  }
  @media (min-width: ${MEDIA.desktop}) {
    padding-left: 5rem;
  }
`

const Title = styled(motion.h1)`
  padding: 0rem 2rem;
  font-size: max(50px, min(7vw, 90px));
  font-size: var(--main-title);
  text-align: center;
  text-transform: uppercase;
  color: var(--background-color);
  text-shadow: 4px 4px 2px hsl(207 5% 13% / 1);
  text-align: left;
  @media (min-width: ${MEDIA.tablet}) {
    max-width: 85%;
  }
  @media (min-width: ${MEDIA.desktop}) {
    max-width: 75%;
  }
`

const Subtitle = styled(motion.h2)`
  padding: 0rem 2rem;
  font-size: 1.5rem;
  text-align: center;
  text-transform: uppercase;
  color: var(--background-color);
  text-shadow: 4px 4px 2px hsl(207 5% 13% / 1);
  text-align: center;
  @media (min-width: ${MEDIA.tablet}) {
    max-width: 85%;
    text-align: left;
  }
  @media (min-width: ${MEDIA.desktop}) {
    max-width: 75%;
  }
`

const HeroParagraph = styled(motion.p)`
  position: relative;
  padding: 1.5rem 2rem;
  margin: 2rem 0;
  background: rgba(245, 245, 245, 0.9);
  border-radius: 5px;
  /* box-shadow: 3px 3px 15px 2px hsl(0, 0%, 80%); */
  width: fit-content;
  text-align: center;
  @media (min-width: ${MEDIA.tablet}) {
    text-align: left;
  }
  @media (min-width: ${MEDIA.desktop}) {
  }
`
