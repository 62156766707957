import React, {useState, useEffect} from 'react'
import styled from '@emotion/styled'
import {motion, Variants} from 'framer-motion'

import {MEDIA} from '../../../utils/constants'
import {emailValidator} from '../../../utils/validators'

const errorMessageVariants: Variants = {
  closed: {opacity: 0, y: '-10px', visibility: 'hidden'},
  open: {
    opacity: [1, 1, 1, 1, 0],
    y: [-10, 0, 0],
    visibility: ['visible', 'hidden'],
    transition: {duration: 15},
  },
}

const ServicesContact = () => {
  const [senderName, setSenderName] = useState<string>('')
  const [senderEmail, setSenderEmail] = useState<string>('')
  const [message, setMessage] = useState<string>('')
  // const [validationError, SetValidationError] = useState<any>({
  //   senderName: false,
  //   senderEmail: false,
  //   message: false,
  // })
  const [emailError, setEmailError] = useState<boolean>(false)
  const [messageError, setMessageError] = useState<boolean>(false)

  const [flag, setFlag] = useState<boolean>(false)

  useEffect(() => {
    if (senderEmail.length > 0 && senderName.length > 0 && message.length > 0) {
      setFlag(true)
    } else {
      setFlag(false)
    }
  }, [senderName, senderEmail, message])

  const contactHandler = (e) => {
    e.preventDefault()
    if (!emailValidator(senderEmail)) {
      setEmailError(true)
      return
    }
    setMessageError(message.length === 0)
    console.log('flag', flag)
    if (flag) {
      console.log('test')
      // check error
      const data = {
        senderName,
        senderEmail,
        message,
      }

      fetch(`${process.env.GATSBY_API_URL}/contact`, {
        // fetch(`http://localhost:1337/contact`, {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then((res) => res.json())
        .then((res) => {
          console.log('res', res)
          setSenderName('')
          setSenderEmail('')
          setMessage('')
        })
        .catch((err) => {
          console.log('err', err)
        })
    }
  }

  return (
    <StyledWrap>
      <ContentDiv>
        <h2>Contact Us</h2>
        <FormWrapper data-css='contact-message'>
          <form onSubmit={contactHandler}>
            <Info>
              <TextLabel htmlFor='name'>
                Name
                <input
                  type='text'
                  name='name'
                  placeholder='Name'
                  value={senderName}
                  onChange={(e) => setSenderName(e.target.value)}
                  required
                />
              </TextLabel>

              <TextLabel htmlFor='email'>
                Email
                <Input
                  type='email'
                  name='email'
                  placeholder='Email'
                  value={senderEmail}
                  error={emailError}
                  onChange={(e) => {
                    setEmailError(false)
                    setSenderEmail(e.target.value)
                  }}
                />
              </TextLabel>
            </Info>
            <TextLabel htmlFor='message'>
              Inquiry
              <Textarea
                name='message'
                value={message}
                error={messageError}
                onChange={(e) => {
                  setMessageError(false)
                  setMessage(e.target.value)
                }}
                style={{resize: 'none'}}
                rows={5}
              />
            </TextLabel>
            <SubmitBtn
              type='submit'
              flag={flag}
              disabled={!flag}
              whileHover={{scale: 1.1}}
              whileTap={{scale: 0.9}}
            >
              Send
            </SubmitBtn>
          </form>
          <Error
            data-css='error'
            animate={emailError ? 'open' : 'closed'}
            variants={errorMessageVariants}
          >
            <p>{emailError ? "don't forget to enter an email address" : ''}</p>
          </Error>
        </FormWrapper>
      </ContentDiv>
    </StyledWrap>
  )
}

export default ServicesContact

const StyledWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 5rem 0;
  h2 {
    padding: 0.5rem 1rem;
    margin: 0rem 0 1rem;
    font-size: 1.5rem;
    background: hsl(0, 0%, 100%);
    border-radius: 0.5rem;
  }
  @media (min-width: ${MEDIA.tablet}) {
  }
`

const ContentDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;
  padding: 1rem 1rem;
  background: var(--formColor);
  border-radius: 0.5rem;
  box-shadow: 0 2px 5px 1px rgba(0, 0, 0, 0.3);
  @media (min-width: ${MEDIA.tablet}) {
    width: 80%;
    padding: 2rem 2rem;
  }
`

const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 2rem 1rem;
  background: white;
  border-radius: 0.5rem;

  label {
    display: flex;
    flex-direction: column;
    align-items: left;
  }
  form {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  @media (min-width: ${MEDIA.tablet}) {
  }
`

const Info = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  label {
    width: 45%;
    margin: 0;
  }
  input {
    padding: 0.5rem;
  }
  @media (min-width: ${MEDIA.tablet}) {
  }
`
const TextLabel = styled.label`
  width: 100%;
  margin-top: 1rem;
  font-size: 0.85rem;
`
const Input = styled.input<{error: boolean}>`
  border: 1px solid ${(prop) => (prop.error ? 'red' : 'transparent')};
`

const Textarea = styled.textarea<{error: boolean}>`
  border: 1px solid ${(prop) => (prop.error ? 'red' : 'transparent')};
  padding: 0.5rem;
`

const Error = styled(motion.div)<() => any>`
  position: absolute;
  margin: 0 auto;
  width: 100%;
  left: 0;
  p {
    background-color: white;
    color: red;
    padding: 0.25rem;
    border-radius: 0.25rem;
    width: fit-content;
    margin: 0 auto;
  }
`

const SubmitBtn = styled(motion.button)<{flag: boolean}>`
  height: 2rem;
  margin: 2rem auto 0rem;
  padding: 0rem 3rem;
  background-color: var(--formColor);
  color: white;
  font-weight: 600;
  border: none;
  border-radius: 0.25rem;
  filter: ${(prop) => (prop.flag ? 'none' : 'grayScale(100%)')};
  background-color: ${(prop) => (prop.flag ? '#d76c79' : '#e4e1e7')};
  &:hover {
    cursor: ${(prop) => (prop.flag ? 'pointer' : 'not-allowed')};
  }
`
