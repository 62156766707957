import React from 'react'
import styled from '@emotion/styled'

const ServicesFooter = () => {
  return (
    <StyledDiv>
      <div>
        <Trademark>JL Services</Trademark>
      </div>
      <div>
        <MailLink href='mailto:whatdoesjustice@gmail.com'>
          whatdoesjustice@gmail.com
        </MailLink>
      </div>
    </StyledDiv>
  )
}

export default ServicesFooter

const StyledDiv = styled.div`
  min-height: 3rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--footColor);
  text-align: center;
  color: whitesmoke;
  div {
    padding: 0 2rem;
  }
`

const Trademark = styled.span`
  font-weight: bold;
`

const MailLink = styled.a`
  font-style: italic;
`
