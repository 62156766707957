import React, {useEffect, useState} from 'react'
import {Link} from 'gatsby'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'

import {IoMdArrowDropup} from 'react-icons/io'

import Seo from './seo'
import ServicesFooter from './ServicesComponents/ServicesFooter'
import GlobalStyle from './GlobalStyle'
import JLLogo from '../assets/logo/JLLogo.png'

import {MEDIA} from '../utils/constants'
import UnstyledButton from './UnstyledButton'

const servicesColorsObject = {
  navColor: '#f9fcfb',
  imageColor: '#866577',
  cardsColor: '#877882',
  boxesColor: '#b1a5ab',
  formColor: '#c19fa3',
  footColor: '#adaaa9',
}

const SecondaryLayout = ({children}) => {
  const [isScrolled, setIsScrolled] = useState(false)

  /*
  function that changes the class of the navbar on scroll
  */
  const toggleNavbarClass = () => {
    const navbar = document.querySelector('nav')
    const navbarHeight = navbar.offsetHeight
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop
    const newIsScrolled = scrollTop > navbarHeight
    if (newIsScrolled !== isScrolled) {
      setIsScrolled(newIsScrolled)
      navbar.classList.toggle('scrolled', newIsScrolled)
    }
  }

  useEffect(
    function listenToScroll() {
      window.addEventListener('scroll', toggleNavbarClass)

      return () => window.removeEventListener('scroll', toggleNavbarClass)
    },
    [isScrolled],
  )

  const scrollTop = () => {
    window.scrollTo({top: 0, behavior: 'smooth'})
  }

  return (
    <Wrapper id='layoutWrapper' data-css='mainLayout-Wrapper'>
      <Seo />
      <GlobalStyle
        headerFont={5} // 5 => Ledger
        contentFont={2} // 2 => Faustina
        colorObject={servicesColorsObject}
      />
      <Navbar>
        <UnstyledButton
          onClick={() => {
            scrollTop()
          }}
        >
          <IconDiv>
            <IoMdArrowDropup />
          </IconDiv>
        </UnstyledButton>

        <TitleInNav>Services</TitleInNav>

        <Link to={'/'}>
          <GoBack>
            <img src={JLLogo} alt='JL logo' />
          </GoBack>
        </Link>
      </Navbar>
      <Main data-css='mainLayout-Main'>{children}</Main>
      <ServicesFooter />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  min-height: 100vh;
  scroll-behavior: smooth;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media (min-width: ${MEDIA.tablet}) {
    display: grid;
    grid-template-rows: auto 1fr;
    grid-template-columns: 100%;
    grid-template-areas: 'navbar' 'main';
  }
`

const Navbar = styled.nav`
  grid-area: navbar;
  width: 100%;
  height: 'var(--navbar-height)';
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: var(--navColor);

  &.scrolled {
    opacity: 0.25;
  }
  &:hover {
    opacity: 1;
  }
`

const TitleInNav = styled.h1`
  padding: 0 0 0.5rem 0;
  font-size: 1.25rem;
  text-decoration: underline;
`

const IconDiv = styled.div`
  padding: 0 1rem;
  font-size: 2rem;
`

const Main = styled.main`
  position: relative;
  grid-area: main;
  overflow: auto;
  max-width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  z-index: 0;
  background-color: var(--secondary1);
`

const GoBack = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.25rem 0.5rem;
  img {
    max-height: calc(var(--navbar-height) - 0.5rem);
  }
`

SecondaryLayout.propTypes = {
  children: PropTypes.node,
}

export default SecondaryLayout
