import React from 'react'
import styled from '@emotion/styled'
import {graphql} from 'gatsby'

// import rehypeRaw from 'rehype-raw'
import ReactMarkdown from 'react-markdown'

import {MEDIA} from '../utils/constants'

import SecondaryLayout from '../components/SecondaryLayout'
import PageTitleSecondary from '../components/PageTitle/PageTitleSecondary'
import ServicesContact from '../components/ServicesComponents/ServicesContact/ServicesContact'

const Services = ({data}) => {
  const defaultTitle = 'what do you have to say?'

  let title = data?.strapiWebpage?.page_in_lang[0]?.title
    ? data?.strapiWebpage?.page_in_lang[0]?.title
    : defaultTitle

  return (
    <SecondaryLayout>
      <Wrapper data-css='services-Wrapper'>
        <PageTitleSecondary
          title={title}
          subtitle={'subtitle that is a bit longer'}
          paragraph={
            'this is a paragraph that is the longest of the string. it might even have a period in it'
          }
        />

        <BriefLeadDiv>
          <h2>Brief Lead In</h2>
          <p>
            Small lead in explanation paragraph giving a general impression of
            the purpose of this page through a few lines. Small lead in
            explanation paragraph giving a general impression of the purpose of
            this page through a few lines.
          </p>
        </BriefLeadDiv>
        <CenterDiv>
          <ReactMarkdown
            escapeHtml={false}
            source={data.strapiWebpage.page_in_lang[0].centered_content}
          />
        </CenterDiv>

        <ServicesContent>
          <ReactMarkdown
            escapeHtml={false}
            children={data.strapiWebpage.page_in_lang[0].content}
            transformImageUri={(uri) =>
              uri.startsWith('http')
                ? uri
                : `${process.env.IMAGE_BASE_URL}${uri}`
            }
          />
        </ServicesContent>

        <BriefLeadDiv>
          <h3>Brief Lead Out</h3>
          <p>
            Small lead out invitation paragraph giving encouragement to reach
            out for more information or to start a discussion. Small lead out
            invitation paragraph giving encouragement to reach out for more
            information or to start a discussion.
          </p>
        </BriefLeadDiv>
        <ServicesContact />
      </Wrapper>
    </SecondaryLayout>
  )
}

export const query = graphql`
  query WebpageServicesQuery {
    strapiWebpage(name: {eq: "services"}) {
      strapiId
      page_in_lang {
        title
        lang
        content
        centered_content
      }
    }
    allStrapiBio {
      edges {
        node {
          role
          name
          content
        }
      }
    }
  }
`

export default Services

const Wrapper = styled.div`
  max-height: 100%;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  p {
    position: relative;
    padding: 2rem 3rem;
    background: whitesmoke;
    border-radius: 5px;
  }
`

const BriefLeadDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  max-width: 75%;
  margin: 0 auto;
  padding: 8rem 4rem 2rem;
  h2 {
    font-size: 1.5rem;
    text-decoration: underline;
  }
  h3 {
    font-size: 1.3rem;
    /* text-decoration: underline; */
  }
  p {
    font-size: 1.2rem;
    text-align: center;
    background: none;
  }
`

const CenterDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: stretch;
  flex-wrap: wrap;
  gap: 1rem;
  padding: calc(var(--navbar-height) * 2) 3rem;
  text-align: center;
  p {
    border-radius: 0.25rem;
    background-color: var(--cardsColor);
    box-shadow: 2px 2px 15px 3px hsl(0, 0%, 80%);
    strong {
      font-size: 1.2rem;
    }
  }

  @media (min-width: ${MEDIA.tablet}) {
    p {
      flex: 1;
      min-width: 30ch;
      max-width: 30ch;
    }
  }
`

const ServicesContent = styled.div`
  position: relative;
  display: grid;
  flex-direction: column;
  padding: 6rem 2rem;
  background-color: var(--boxesColor);
  box-shadow: 2px 2px 6px 8px var(--boxesColor);
  p {
    margin: 0rem 0;
    padding: 3rem 3rem;
    border-radius: 0.25rem;
    background-color: var(--secondary1);
    box-shadow: none;
  }

  @media (min-width: ${MEDIA.desktop}) {
    padding: 6rem 3rem;
    p {
      max-width: 45%;
      margin: 0rem 0;
      padding: 3rem 3rem;
      box-shadow: 2px 2px 15px 3px hsl(0, 0%, 50%);
    }
  }

  p:nth-of-type(2n - 1) {
    margin-top: -3rem;
    text-align: left;
    justify-self: flex-start;
  }
  p:nth-of-type(2n) {
    margin-top: -3rem;
    text-align: right;
    justify-self: flex-end;
  }
`
