import React from 'react'
import styled from '@emotion/styled'

const UnstyledButton = ({onClick, children}) => {
  return <DestyledButton onClick={onClick}>{children}</DestyledButton>
}

export default UnstyledButton

const DestyledButton = styled.button`
  margin: 0;
  border: none;
  background: transparent;
  border-radius: 0.25rem;
  &:hover {
    cursor: pointer;
  }
`
